import React from "react"
import { InlineWidget } from "react-calendly"
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import NavigationBar from "../../components/NavigationBar"
import phoneIcon from "../../images/phoneIcon.png"
import peopleIcon from "../../images/peopleIcon.png"
import pointIcon from "../../images/pointIcon.png"
import testimonialOne from "../../images/testimonial-one.png"
import testimonialTwo from "../../images/testimonial-two.png"
import testimonialThree from "../../images/testimonial-three.png"
import "./style.scss"

const GetStarted = () => (
  <Layout>
    <SEO title="Get Started" />
    <div className="getStartedPageContainer">
      <NavigationBar />
      <div className="banner">
        <div className="container">
          <h1>Your child's journey starts here</h1>
        </div>
      </div>
      <div className="appointmentWrapper">
        <div className="container">
          <div className="appointment">
            <div className="column">
              <div className="content">
                <div className="iconCard">
                  <div className="icon">
                    <img src={phoneIcon} alt="phone icon" />
                  </div>
                  <div className="iconImageContent">
                    <h3>
                      Step 1: <span className="greenBranding">Book a Call</span>
                    </h3>
                    <p>
                      Use the form on this page to book a time that works for
                      you for a short consultation call. We’ll call you promptly
                      at that time to get to know you &amp; your child better,
                      and provide you with all of the information you need to
                      see if we are a great fit.
                    </p>
                  </div>
                </div>

                <div className="iconCard">
                  <div className="icon">
                    <img src={peopleIcon} alt="people icon" />
                  </div>
                  <div className="iconImageContent">
                    <h3>
                      Step 2:{" "}
                      <span className="greenBranding">
                        Come In For First Session
                      </span>
                    </h3>
                    <p>
                      We will meet &amp; greet you at our unique training centre
                      where you will witness instant transformation, great
                      positive energy and tons of fun. Your child will receive
                      full uniform, break a real board and achieve their first
                      belt - their white belt.
                    </p>
                  </div>
                </div>

                <div className="iconCard">
                  <div className="icon">
                    <img src={pointIcon} alt="point icon" />
                  </div>
                  <div className="iconImageContent">
                    <h3>
                      Step 3:{" "}
                      <span className="greenBranding">
                        Choose the Programme For Your Child
                      </span>
                    </h3>
                    <p>
                      You might be just looking to improve your child fitness or
                      or boost their confidence. We will work with you to find
                      the right programme for you and your parenting goals. For
                      a monthly fee, you get far more than you’d expect.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column calendar">
              <div className="calendly-inline-widget" data-url="">
                <InlineWidget url="https://calendly.com/kravmagamedway/martial-arts-consultation-call" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ctaWrapper">
        <div className="container">
          <div className="content">
            <h2>
              Take the first step and book a call. We'll take it from there.
            </h2>
          </div>
        </div>
      </div>
      <div className="testimonialsWrapper">
        <div className="container">
          <div className="testimonials">
            <div className="testimonial">
              <img src={testimonialOne} alt="Testimonial 1" />
              <p className="text">
                "It’s a long process, you’re building up, you’re learning all
                the skills, the confidence, it’s about honesty, it’s about
                persevering, not giving up. And everything comes together to
                mean a black belt"
              </p>
            </div>
            <div className="testimonial">
              <img src={testimonialTwo} alt="Testimonial 2" />
              <p className="text">
                "Practical and easy to remember techniques that work under
                pressure, delivered in a class with great mix of skill,
                discipline &amp; fun. My son loves it!"
              </p>
            </div>
            <div className="testimonial">
              <img src={testimonialThree} alt="Testimonial 3" />
              <p className="bold">
                "I love the values the club encourages children to adopt;
                discipline and perseverance among others. The classes are super
                and I would highly recommend to anyone interested."
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default GetStarted
